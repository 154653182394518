<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, onMounted, watch } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
import MethodService from "../../service/MethodService";
import ValidService from "../../service/ValidService";
import Data from "./dataQuanLyDonVi";
import LanguageService from "../../service/LanguageService";
import { langWeb } from "../../stores/lang";
const lang_web = langWeb()
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const { t } = useI18n();
const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const router = useRoute();
const routerPush = useRouter();
const validateFlag = ref(false)

// validate form
const validateURL = (rule, value, callback) => {
  var filter = /^(https?:)?\/\//;
  if (value) {
    if (value.trim().match(filter)) {
      callback();
    } else {
      callback(new Error(t("t_error_url_format")));
    }
  } else callback();
};

const rulesForm = reactive({
  name: [
    ValidService.required,
    ValidService.checkNameAllowVN,
    ValidService.checkMaxLength(50),
  ],
  url: [{ validator: validateURL, trigger: "blur" }],
});

// thêm mới đơn vị
const addNewDeparment = (formEl) => {
  validateFlag.value = true
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (!valid) return;
    const deparment_object = {
      name: dataForm.value.name.trim(),
    };
    if (dataForm.value.url?.length > 0)
      deparment_object.url = dataForm.value.url;
    try {
      const newId = await mushroom.department.createAsync(deparment_object);
      if (newId) toastr.success(t("toastr_add_department_success"));
      routerPush.go(-1);
    } catch (e) {
      if (e?.detail === "Violate unique key constraint") {
        toastr.error(t("toastr_department_name_exsited"));
      } else MethodService.showError(e.code);
    }
  });
};

// cập nhật đơn vị
const updateDepartment = (formEl) => {
  validateFlag.value = true
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (!valid) return;
    const deparment_object = {
      id: router.params.id,
      name: dataForm.value.name.trim(),
    };
    if (dataForm.value.url?.length > 0)
      deparment_object.url = dataForm.value.url;
    try {
      const newId = await mushroom.department.partialUpdateAsync(
        deparment_object
      );
      if (newId.result === router.params.id)
        toastr.success(t("toastr_edit_department_success"));
      routerPush.go(-1);
    } catch (e) {
      if (e?.detail === "Violate unique key constraint") {
        toastr.error(t("toastr_department_name_exsited"));
      } else MethodService.showError(e.code);
    }
  });
};
// function lấy thông tin chi tiết đơn vị
const getDepartmentByID = async () => {
  try {
    const response = await mushroom.department.findByIdAsync({
      id: router.params.id,
      fields: "id,name,url",
    });
    if (response.result) dataForm.value = response.result;
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const trimStringName = () => {
  dataForm.value.name = dataForm.value.name.trim();
};
const trimStringURL = () => {
  dataForm.value.url = dataForm.value.url.trim();
};

watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(async () => {
  if (router.name === "CapNhatDonVi") {
    await getDepartmentByID();
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'ThemMoiDonVi'">
          {{ $t("t-add-department") }}
        </h3>
        <h3 v-else>{{ $t("t-update-department") }}</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item :label="t('t-department')" prop="name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.name"
                :placeholder="t('t-place-department')"
                clearable
                @change="trimStringName"
              />
            </el-form-item>

            <el-form-item label="URL" prop="url">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.url"
                :placeholder="$t('t-place-url')"
                clearable
                @change="trimStringURL"
              />
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-border"
                @click="addNewDeparment(ruleFormRef)"
                v-if="router.name === 'ThemMoiDonVi'"
              >
                {{ $t("t-add") }}
              </button>

              <button
                type="button"
                class="btn btn-secondary btn-border"
                @click="updateDepartment(ruleFormRef)"
                v-else
              >
                {{ $t("t-update") }}
              </button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
